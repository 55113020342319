import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClassNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import { showAbout } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectInsuranceModal,
  makeSelectIsFreshworksLoaded,
} from 'containers/Main/selectors';
import LandingMenuSelector from 'components/LandingMenuSelector';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useWindowSize from 'components/useWindowSize';
import { isIE } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';
import freshworksUtils from 'utils/freshworksUtils';
import useInCrisis from 'containers/InCrisis/useInCrisis';
import useLanguageSelector from 'components/Hooks/useLanguageSelector';
import AtTheClinicButton from './AtTheClinicButton';
import LanguageSelector from '../LanguageSelector';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    height: 36,
    minHeight: 36,
    background: 'white',
    width: '100%',
    borderBottom: '1px solid #E3E3E3',
    [theme.breakpoints.down('900')]: {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('600')]: {
      position: 'inherit',
      height: 'auto',
    },
  },
  elevatedRoot: {
    zIndex: 1301,
  },
  stick: {
    top: 0,
    background: '#fff',
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: 0,
    },
    width: '100%',
    padding: '0 5%',
    [theme.breakpoints.down('350')]: {
      padding: 0,
    },
  },
  rightMenuWrapper: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1140')]: {
      right: '5%',
    },
  },
  rightMenuWrapperIE11: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    top: 0,
  },
  rightMenuButton: {
    ...theme.typography.overline,
    color: '#6C6C6C',
    padding: '0 5px',
    marginRight: 12,
    textTransform: 'uppercase',
    '&:last-child': {
      marginRight: 0,
    },
  },
  columnized: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: '16px',
  },
}));

const LanguageBanner = ({
  hasMultipleLanguage,
  hideAbout,
  hideInCrisis,
  orientation = 'row',
}) => {
  const classes = useStyles();
  const insuranceModal = useSelector(makeSelectInsuranceModal());
  const clientDetails = useSelector(makeSelectClientDetails());
  const isFreshworksLoaded = useSelector(makeSelectIsFreshworksLoaded());
  const [footerSiteCopy] = useSiteCopySelector(['footer']);
  const [hasBanner, setHasBanner] = useState(false);
  const hasMultipleLanding =
    _get(clientDetails, 'landingLinksCollection.items', [])
      .filter(item => !_isEmpty(item))
      .filter(({ showMenu }) => showMenu === 'yes').length > 1;
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowSize();
  const isMobile = width <= 600;
  const isTopicPage = location.pathname.includes('/topics/');
  const isCompanyPage = location.pathname.includes('/company/');
  const isEmbed = isEmbedded();
  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const showAtTheClinicButton = _get(
    clientDetails,
    'metadata.showAtTheClinicButton',
    false,
  );
  const { showInCrisisInHeader, renderInCrisisButton } = useInCrisis({
    buttonClassName: classes.rightMenuButton,
  });

  const {
    languageData: data,
    isSuccess,
    isLoading: isFetching,
  } = useLanguageSelector(hasMultipleLanguage);

  const [hideAboutLink, setHideAboutLink] = useState(
    _get(clientDetails, 'metadata.hideAboutLink', false),
  );

  useEffect(() => {
    if (!_isEmpty(clientDetails))
      setHideAboutLink(_get(clientDetails, 'metadata.hideAboutLink', false));
  }, [clientDetails]);

  useEffect(() => {
    if (!isFetching && !_isEmpty(data)) {
      setHasBanner(true);
    }
  }, [data, isFetching]);

  const handleAboutClick = () => dispatch(showAbout(true));

  const handleSupportClick = () => {
    freshworksUtils.show();
  };

  if (
    (!hasBanner && !hasMultipleLanding && !showInCrisisInHeader) ||
    isCompanyPage
  ) {
    return null;
  }

  const shouldShowAbout = !hideAbout && !hideAboutLink;
  const shouldShowSupportButton = isFreshworksLoaded && !isMobile;

  const languageSelector = () =>
    isSuccess && <LanguageSelector hasMultipleLanguage={hasMultipleLanguage} />;

  if (useAppEmbedStyle) {
    return hasBanner ? languageSelector() : null;
  }

  return (
    <section
      className={ClassNames(classes.root, {
        [classes.stick]: isTopicPage,
        [classes.elevatedRoot]: insuranceModal.visible,
      })}
    >
      <div
        className={ClassNames(classes.container, {
          [classes.columnized]: orientation === 'column',
        })}
      >
        {hasBanner && languageSelector()}
        {hasMultipleLanding && <LandingMenuSelector />}
        <div
          className={ClassNames({
            [classes.rightMenuWrapper]: true,
            [classes.rightMenuWrapperIE11]: isIE(),
          })}
        >
          {showAtTheClinicButton ? (
            <AtTheClinicButton />
          ) : shouldShowAbout || shouldShowSupportButton ? (
            <>
              {shouldShowSupportButton && (
                <ButtonBase
                  onClick={handleSupportClick}
                  disableRipple
                  disableTouchRipple
                  className={classes.rightMenuButton}
                >
                  {_get(footerSiteCopy, 'pageCopy.supportLabel', 'Support')}
                </ButtonBase>
              )}
              {shouldShowAbout && (
                <ButtonBase
                  onClick={handleAboutClick}
                  disableRipple
                  disableTouchRipple
                  className={classes.rightMenuButton}
                >
                  {_get(footerSiteCopy, 'pageCopy.aboutLabel')}
                </ButtonBase>
              )}
            </>
          ) : null}
          {!hideInCrisis && renderInCrisisButton()}
        </div>
      </div>
    </section>
  );
};

export default React.memo(LanguageBanner);
