import React from 'react';
import useSiteCopySelector from 'components/useSiteCopySelector';
import _classNames from 'classnames';
import _get from 'lodash/get';
import { useMyLearningBadgeStyles } from '../MyLearningBadge';

export const NewBadge = ({ className }) => {
  const classes = useMyLearningBadgeStyles();
  const [siteCopy] = useSiteCopySelector(['navbar-menu']);
  return (
    <span className={_classNames(classes.badge, className)}>
      {_get(siteCopy, ['pageCopy', 'dialog-menu', 'new'])}
    </span>
  );
};
