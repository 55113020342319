import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { isTakenSeriesCompleted, isTakenSeriesInProgress } from './utils';

const getSeriesProgress = (entry, taken) => {
  const resourcesLength = _get(entry, 'fields.resources.length', 0);
  const completedResourcesCount = _get(taken, 'completedResourcesIds.length');
  const progress = completedResourcesCount
    ? Math.round((completedResourcesCount / resourcesLength) * 100)
    : 0;

  return progress;
};

export const getAllSeries = createSelector(
  [state => state.series.takenSeries, state => state.series.allSeries],
  (takenSeries, allSeries) => {
    const completedIds = Object.keys(takenSeries).filter(
      key => takenSeries[key].completed,
    );

    const uncompletedSeries = allSeries.filter(
      series => !completedIds.includes(series.sys.id),
    );
    const completedSeries = allSeries.filter(series =>
      completedIds.includes(series.sys.id),
    );

    const sortedUncompletedSeries = uncompletedSeries.sort((a, b) => {
      const orderA = a.fields.learningLabOrder ?? Number.MIN_SAFE_INTEGER;
      const orderB = b.fields.learningLabOrder ?? Number.MIN_SAFE_INTEGER;

      if (orderA !== orderB) {
        return orderB - orderA;
      }

      const progressA = getSeriesProgress(a, takenSeries[a.sys.id]) ?? 0;
      const progressB = getSeriesProgress(b, takenSeries[b.sys.id]) ?? 0;

      if (progressA !== progressB) {
        return progressB - progressA;
      }

      const updatedAtA = a.fields.updatedOrReviewed
        ? new Date(a.fields.updatedOrReviewed).getTime()
        : 0;

      const updatedAtB = b.fields.updatedOrReviewed
        ? new Date(b.fields.updatedOrReviewed).getTime()
        : 0;
      return updatedAtB - updatedAtA;
    });

    const sortedCompletedSeries = completedSeries.sort((a, b) => {
      const completedAtA = takenSeries[a.sys.id]?.completedAt ?? 0;
      const completedAtB = takenSeries[b.sys.id]?.completedAt ?? 0;
      return completedAtB - completedAtA;
    });

    return [...sortedUncompletedSeries, ...sortedCompletedSeries];
  },
);

export const getSeriesTakenInSession = state => state.series.takenSeries;

export const getCompletedSeries = createSelector(
  state => state.series.takenSeries,
  takenSeries =>
    Object.keys(takenSeries).filter(id =>
      isTakenSeriesCompleted(takenSeries[id]),
    ),
);

export const getTakenSeriesProcessing = state =>
  state.series.takenSeriesProcessing;

export const getInProgressSeries = createSelector(
  state => state.series.takenSeries,
  takenSeries =>
    Object.keys(takenSeries).filter(id =>
      isTakenSeriesInProgress(takenSeries[id]),
    ),
);
