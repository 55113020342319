import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { makeSelectLanguage } from 'containers/Main/selectors';
import { showLanguageBanner, setLanguage } from 'containers/Main/actions';
import getLanguageByCode from 'containers/Main/services/getLanguageByCode.gql';
import { isLocalStorageAllowed } from 'utils/stringUtils';

export const useLanguageSelector = (hasMultipleLanguage = false) => {
  const dispatch = useDispatch();
  const language = useSelector(makeSelectLanguage());

  const { data, status, isFetching, error } = useQuery(
    language && ['language', language],
    getLanguageByCode,
    {
      enabled: hasMultipleLanguage && !!language,
      retry: 1,
      onError: () => {
        dispatch(showLanguageBanner(false));
      },
      // Prevent automatic refetching on window focus
      refetchOnWindowFocus: false,
      // Prevent cache invalidation on component mount
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (!isFetching) {
      if (!data || error) {
        if (isLocalStorageAllowed()) {
          localStorage.removeItem('language');
        }
        dispatch(showLanguageBanner(false));
      } else {
        dispatch(showLanguageBanner(hasMultipleLanguage));
      }
    }
  }, [data, error, isFetching, dispatch, hasMultipleLanguage]);

  const setCurrentLanguage = newLanguage => {
    if (newLanguage === language) return;
    dispatch(setLanguage(newLanguage));
  };

  return {
    currentLanguage: language,
    languageData: data,
    isLoading: isFetching,
    isSuccess: status === 'success',
    error,
    setCurrentLanguage,
  };
};

export default useLanguageSelector;
