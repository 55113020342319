import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  getTakenPracticesSuccess,
  setPracticesProcessing,
  getViewedPracticesResult,
} from './actions';

const initialState = {
  allPractices: [],
  processing: false,
  viewedPractices: [],
  takenPractices: {},
};

const practicesReducer = handleActions(
  {
    [getTakenPracticesSuccess]: (state, action) =>
      update(state, {
        allPractices: { $set: action.payload.allPractices },
        takenPractices: { $set: action.payload.takenPractices },
        processing: { $set: false },
      }),
    [setPracticesProcessing](state, action) {
      return update(state, {
        processing: { $set: action.payload },
      });
    },
    [getViewedPracticesResult](state, action) {
      return update(state, { viewedPractices: { $set: action.payload } });
    },
  },
  initialState,
);

export default practicesReducer;
