import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { put, select, take, takeLatest } from 'redux-saga/effects';
import contentfulClient from 'utils/contentfulService';
import { getContentfulLocaleFilter } from 'containers/Main/utils';
import { getIsPracticesEnabled } from 'containers/Main/selectors';
import {
  GET_CLIENT_DETAILS_SUCCESS,
  GET_SITE_CONFIG_SUCCESS,
} from 'containers/Main/constants';
import { getFirebase } from 'react-redux-firebase';
import {
  GET_TAKEN_PRACTICES,
  GET_VIEWED_PRACTICE,
  VIEW_PRACTICE,
} from './constants';
import {
  getTakenPracticesSuccess,
  setPracticesProcessing,
  getViewedPracticesResult,
  getViewedPractices,
} from './actions';

function* getTakenPracticesSaga() {
  const siteConfig = yield select(state => state.main.siteConfig);

  try {
    yield put(setPracticesProcessing(true));
    if (!siteConfig.length) {
      yield take(GET_SITE_CONFIG_SUCCESS);
    }

    const isPracticesEnabled = yield select(getIsPracticesEnabled);
    if (!isPracticesEnabled) {
      return;
    }

    let clientDetails = yield select(state => state.main.clientDetails);
    if (isEmpty(clientDetails)) {
      yield take(GET_CLIENT_DETAILS_SUCCESS);
      clientDetails = yield select(state => state.main.clientDetails);
    }
    const excludeResourceTypes =
      _get(clientDetails, 'excludeResourceTypes') || [];
    const localeFilters = yield getContentfulLocaleFilter();

    const requestPayload = {
      content_type: 'practice',
      'fields.reviewStatus[in]': 'Accepted',
      'fields.courseContent': false,
      limit: 1000,
      include: 1,
      ...localeFilters,
    };

    const [clientPractices, restPractices] = yield Promise.all([
      contentfulClient.getEntries({
        ...requestPayload,
        'fields.clientInclude.sys.id[in]': clientDetails.sys.id,
      }),
      excludeResourceTypes.includes('Practices')
        ? Promise.resolve([])
        : contentfulClient.getEntries({
            ...requestPayload,
            'fields.clientInclude[exists]': false,
            'fields.clientExclude.sys.id[nin]': [
              clientDetails.sys.id,
              clientDetails?.clientGroup?.sys?.id,
            ].join(','),
          }),
    ]);
    const takenPractices = {};
    const allPractices = [...clientPractices.items, ...restPractices.items];

    yield put(getTakenPracticesSuccess({ takenPractices, allPractices }));
  } catch (error) {
    yield put(setPracticesProcessing(false));
  }
}

function* getViewedPracticesSaga() {
  try {
    const userID = yield getFirebase().auth().currentUser.uid;
    const viewedPracticesDocs = yield getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('viewed_practices')
      .get();
    const viewedPractices = viewedPracticesDocs.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    yield put(getViewedPracticesResult(viewedPractices));
  } catch (error) {
    yield put(getViewedPracticesResult([]));
  }
}

function* viewPracticeSaga({ payload }) {
  try {
    const userID = yield getFirebase().auth().currentUser.uid;
    if (userID != null && !isEmpty(payload)) {
      const { id, name, slug, type } = payload;
      yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('viewed_practices')
        .doc(id)
        .set({
          name,
          slug,
          practiceType: type,
          timestamp: Date.now(),
        });
    }
  } finally {
    yield put(getViewedPractices());
  }
}

export default function* defaultSaga() {
  yield takeLatest(GET_TAKEN_PRACTICES, getTakenPracticesSaga);
  yield takeLatest(GET_VIEWED_PRACTICE, getViewedPracticesSaga);

  yield takeLatest(VIEW_PRACTICE, viewPracticeSaga);
}
