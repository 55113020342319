/**
 *
 * InactiveState
 *
 */

import React, { useEffect } from 'react';
import AppContainer from 'components/AppContainer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import useSiteCopySelector from 'components/useSiteCopySelector';
import CMLogo from 'components/UserReviews/images/cm.svg';
import Mixpanel from 'utils/mixpanelService';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20vh',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '15vh',
    },
  },
  mainText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  pageContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '0 10rem',
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
  },
  logo: {
    width: 120,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

function InactiveState({ clientInactiveCopy }) {
  const [retiredClientSiteCopy] = useSiteCopySelector(['retired-client-page']);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      Mixpanel.track('Retired Client - Viewed', {
        path: window.location.pathname,
      });
    }, 100);
  }, []);

  const parseUrlsInText = text => {
    if (!text) return text;
    // Match both http(s) URLs and crediblemind.com subdomains without protocol
    const urlRegex = /(https?:\/\/[^\s]+|(?:[a-zA-Z0-9-]+\.)?crediblemind\.com[^\s]*)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        const href = part.startsWith('http') ? part : `https://${part}`;
        return (
          <a
            key={index}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const headerText =
    clientInactiveCopy?.header ||
    _get(
      retiredClientSiteCopy,
      'pageCopy.pageHeader',
      'We regret to inform you that this platform is no longer available as a free service.',
    );

  const bodyText =
    clientInactiveCopy?.body ||
    _get(
      retiredClientSiteCopy,
      'pageCopy.pageBody',
      'If you have any questions about CredibleMind or are looking for additional mental health and wellbeing resources, please reach out to your sponsoring organization for more information.',
    );

  return (
    <>
      <AppContainer
        justify="center"
        alignItems="center"
        customClasses={classes}
      >
        <div className={classes.pageContainer}>
          <div>
            <img
              src={_get(retiredClientSiteCopy, 'imageUrl') || CMLogo}
              alt="CredibleMind"
              className={classes.logo}
            />
          </div>
          <div>
            <Typography
              variant="h2"
              className={classes.mainText}
              color="textPrimary"
              align="center"
            >
              {headerText}
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={classes.message} align="center">
              {parseUrlsInText(bodyText)}
            </Typography>
          </div>
        </div>
      </AppContainer>
    </>
  );
}

export default React.memo(InactiveState);
