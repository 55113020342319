import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import confetti from 'canvas-confetti';
import { getScorePercentage } from 'containers/Assessment/utils';
import Mixpanel from 'utils/mixpanelService';

export const getResourceType = item => {
  const type = _get(item, 'sys.contentType.sys.id');
  const resourceType = type;
  const isActivity = resourceType === 'activity';
  const isBlog = _get(item, 'fields.blog', false);

  if (!isActivity) {
    return type;
  }

  if (isBlog) {
    return 'blog';
  }

  if (_get(item, 'fields.type') === 'News') {
    return 'news';
  }

  return 'article';
};

export const getResourceName = resource =>
  _get(resource, 'fields.name') || _get(resource, 'fields.title');

export const findNextIncompleteResource = (
  nextIdx,
  resources,
  completedResourcesIds,
) => {
  const nextResources = resources.slice(nextIdx);

  if (!nextResources.length) {
    return resources.findIndex(
      r => !completedResourcesIds.some(id => id === _get(r, 'sys.id')),
    );
  }

  const incompleteResourceIdx = nextResources.findIndex(
    r => !completedResourcesIds.some(id => id === _get(r, 'sys.id')),
  );
  return incompleteResourceIdx !== -1
    ? incompleteResourceIdx + nextIdx
    : resources.findIndex(
        r => !completedResourcesIds.some(id => id === _get(r, 'sys.id')),
      );
};

export const findNextResourceIndex = (nextIdx, resources, resourceType) => {
  if (!resourceType) return null;

  const nextResources = resources.slice(nextIdx);

  if (!nextResources.length) return null;

  const nextResourceIndex = nextResources.findIndex(
    r => _get(r, 'sys.contentType.sys.id') === resourceType,
  );

  return nextResourceIndex === -1 ? null : nextResourceIndex + nextIdx;
};

export const getInitialActiveIdx = (resources, takenSeries) => {
  if (takenSeries.completed) {
    return null;
  }

  const completedIds = _get(takenSeries, 'completedResourcesIds') || [];
  const lastViewedResource = _get(takenSeries, 'lastViewedResource');
  const isAllResourcesCompleted = resources.every(r =>
    completedIds.some(id => _get(r, 'sys.id') === id),
  );

  if (
    lastViewedResource &&
    (!lastViewedResource.completed || isAllResourcesCompleted)
  ) {
    const found = resources.findIndex(
      r => _get(r, 'sys.id') === lastViewedResource.id,
    );
    if (found !== -1) {
      return found;
    }
  }

  const idx = findNextIncompleteResource(0, resources, completedIds);
  return idx === -1 ? null : idx;
};

export const getSeriesDoc = ({ firestore, userId, seriesId, collection }) =>
  firestore
    .collection(collection)
    .doc(userId)
    .collection('series')
    .doc(seriesId);

export const isTakenSeriesInProgress = takenSeries =>
  !takenSeries.completed &&
  (!!_get(takenSeries, 'completedResourcesIds.length') ||
    Object.values(_get(takenSeries, 'resourcesProgress') || []).some(
      progress => progress,
    ));

export const isTakenSeriesCompleted = takenSeries => takenSeries.completed;

export const showConfetti = () => {
  const triangle = confetti.shapeFromPath({
    path:
      'M0.452148 7.15723L11.9703 0.507225L13.2112 15.9566L0.452148 7.15723Z',
  });
  const oval = confetti.shapeFromPath({
    path:
      'M6.36104 13.4944C9.84043 13.4944 12.661 10.6738 12.661 7.19441C12.661 3.71501 9.84043 0.894409 6.36104 0.894409C2.88164 0.894409 0.0610352 3.71501 0.0610352 7.19441C0.0610352 10.6738 2.88164 13.4944 6.36104 13.4944Z',
  });
  const line1 = confetti.shapeFromPath({
    path:
      'M4.66108 1.00002L7.19626 6.77803L2.83778 11.3405L5.37295 17.1185L1.01447 21.681',
  });
  const line2 = confetti.shapeFromPath({
    path:
      'M0.709469 1.74976L5.19042 1.64492L4.30906 6.03959L8.79004 5.93477L7.90868 10.3294',
  });
  const rectangle = confetti.shapeFromPath({
    path:
      'M5.92459 0.599958L0.409668 1.57239L2.59763 13.981L8.11256 13.0085L5.92459 0.599958Z',
  });
  confetti({
    spread: 180,
    particleCount: 100,
    scalar: 1.2,
    shapes: [triangle, oval, line1, line2, rectangle],
    colors: ['#3BB6E8', '#CB46E8', '#FE6D6D', '#4BCE52', '#B1DD4B'],
    zIndex: 1000,
  });
};

export const getSeriesAnswers = (takenSeries, seriesId) =>
  _get(takenSeries, [seriesId, 'seriesCustomQuestions']) || {};

const SERIES_ASSESSMENT_TREND = {
  BETTER: 'better',
  WORSE: 'worse',
  SAME: 'same',
};

export const getSeriesAssessmentTrend = assessmentResults => {
  const [currentResults, prevResults] = assessmentResults;
  const currentOverallComponent = _get(
    currentResults,
    'scoringsObject.overallComponent',
  );
  const prevOverallComponent = _get(
    prevResults,
    'scoringsObject.overallComponent',
  );
  if (!prevResults || !currentOverallComponent || !prevOverallComponent) {
    return null;
  }

  const valueDirection = _get(currentOverallComponent, 'valueDirection');
  const currentPercentageScore = getScorePercentage(
    currentOverallComponent,
    prevOverallComponent,
  );
  const prevPercentageScore = getScorePercentage(
    currentOverallComponent,
    prevOverallComponent,
  );

  const [score1, score2] =
    valueDirection === 'higherBetter'
      ? [currentPercentageScore, prevPercentageScore]
      : [prevPercentageScore, currentPercentageScore];

  if (score1 > score2) {
    return SERIES_ASSESSMENT_TREND.BETTER;
  }

  return score1 < score2
    ? SERIES_ASSESSMENT_TREND.WORSE
    : SERIES_ASSESSMENT_TREND.SAME;
};

export const formatSeriesContentfulEntry = entry => {
  if (_isEmpty(_get(entry, 'fields'))) return entry;

  const title = (_get(entry, 'fields.title') || '').trim();
  const name = _get(entry, 'fields.name');

  return {
    ...entry,
    fields: {
      ...entry.fields,
      title: title || name,
    },
  };
};

export const formatSeriesContentfulEntries = data => {
  const items = _get(data, 'items');
  if (_isEmpty(items)) return data;

  return {
    ...data,
    items: data.items.map(formatSeriesContentfulEntry),
  };
};

export const trackSeriesNextClick = ({
  item,
  resource,
  trackingPath,
  completed,
  ...rest
}) => {
  Mixpanel.track('Series - Next - Clicked', {
    slug: _get(item, 'fields.slug'),
    name: _get(item, 'fields.title'),
    resourceSlug: _get(resource, 'fields.slug'),
    resourceName: getResourceName(resource),
    completed,
    path: trackingPath,
    ...rest,
  });
};

export const trackSeriesResourceViewed = ({
  item,
  resource,
  trackingPath,
  ...rest
}) => {
  Mixpanel.track('Series - Resource Viewed', {
    slug: _get(item, 'fields.slug'),
    name: getResourceName(resource),
    path: trackingPath,
    type: getResourceType(resource),
    ...rest,
  });
};

export const trackSeriesCompleted = ({ item, trackingPath, ...rest }) => {
  Mixpanel.track('Series - Completed', {
    slug: _get(item, 'fields.slug'),
    name: _get(item, 'fields.title'),
    path: trackingPath,
    ...rest,
  });
};

export const trackSeriesResourceCompleted = ({
  item,
  resource,
  trackingPath,
  ...rest
}) => {
  Mixpanel.track('Series - Resource Completed', {
    slug: _get(item, 'fields.slug'),
    name: _get(item, 'fields.title'),
    resourceSlug: _get(resource, 'fields.slug'),
    resourceName: getResourceName(resource),
    type: getResourceType(resource),
    path: trackingPath,
    ...rest,
  });
};

export const trackSeriesResourceCompletedClicked = ({
  item,
  resource,
  completed,
  trackingPath,
  ...rest
}) => {
  Mixpanel.track('Series - Resource Completed - Clicked', {
    slug: _get(item, 'fields.slug'),
    name: _get(item, 'fields.title'),
    resourceSlug: _get(resource, 'fields.slug'),
    resourceName: getResourceName(resource),
    path: trackingPath,
    completed,
    ...rest,
  });
};

export const trackSeriesResourceClicked = ({
  item,
  resource,
  trackingPath,
  ...rest
}) => {
  Mixpanel.track('Series - Resource Clicked', {
    slug: _get(item, 'fields.slug'),
    name: _get(item, 'fields.title'),
    resourceSlug: _get(resource, 'fields.slug'),
    resourceName: getResourceName(resource),
    path: trackingPath,
    ...rest,
  });
};
