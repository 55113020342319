import { createAction } from 'redux-actions';
import {
  GET_TAKEN_PRACTICES,
  GET_TAKEN_PRACTICES_SUCCESS,
  GET_VIEWED_PRACTICE,
  GET_VIEWED_PRACTICE_RESULT,
  VIEW_PRACTICE,
  SET_PRACTICES_PROCESSING,
} from './constants';

export const getTakenPractices = createAction(GET_TAKEN_PRACTICES);
export const getTakenPracticesSuccess = createAction(
  GET_TAKEN_PRACTICES_SUCCESS,
);
export const setPracticesProcessing = createAction(SET_PRACTICES_PROCESSING);

export const getViewedPractices = createAction(GET_VIEWED_PRACTICE);
export const getViewedPracticesResult = createAction(
  GET_VIEWED_PRACTICE_RESULT,
);

export const viewPractice = createAction(VIEW_PRACTICE);
